import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './Reducer/ProfileReducer';
import avtar from "../assets/images/default-avatar.jpg";
const initialState = {
  profileImage: localStorage.getItem("profileImage") 
  || avtar,
  role:"",
  otherService:"",
  setOtherServiceOn:false

};
const store = configureStore({
  reducer: {
    profile: profileReducer,
    role: profileReducer    
  }, 
  preloadedState: initialState,
});

export default store;
