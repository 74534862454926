import avtar from "../../assets/images/default-avatar.jpg";
const initialState = {
  profileImage:localStorage.getItem("profileImage")
  ??avtar,
  role:"" ,
  otherService: "",
  setOtherServiceOn: false,
};

const profileReducer = (state = initialState, action) => {
    if (action.type==="UPDATE_PROFILE_IMAGE") {
        return {
          ...state,
          profileImage: action.payload,
        }
      }
      if (action.type==="USER_ROLE") {
        return {
          ...state,
          role: action.payload,
        }
      }
      if (action.type==="USER_ID") {
        return {
          ...state,
          id: action.payload,
        }
      }
    if (action.type === "OTHER_SERVICE") {
        return {
          ...state,
          otherService: action.payload,
        };
    }
    if (action.type === "SET_OTHER_SERVICE_ON") {
      return {
        ...state,
        setOtherServiceOn: action.payload,
      };
    }
      else{return state;}
  };
  
  export default profileReducer;
  






