import { Link } from 'react-router-dom';
import icon3 from '../../assets/images/menu-icon-3.svg'
import icon4 from '../../assets/images/menu-icon-4.svg'
import iconSubscription from "../../assets/images/icon-subscription.svg"
import iconDashboard from "../../assets/images/icon-dashboard.svg"
import iconComplienceUser from "../../assets/images/icon-compli-user.svg"
import iconImessage from "../../assets/images/icon-monitoring.svg"
import iconReport from "../../assets/images/reportIcon.svg";
import iconWhatsapp from "../../assets/images/icon-whatsapp.svg";
import iconKeyword from "../../assets/images/AI Keyboard.svg";
import { useEffect } from 'react';
import iconkeyword from "../../assets/images/AI Keyboard.svg"
import iconManageAdmin from "../../assets/images/mange-admin.svg"
import { useSelector } from 'react-redux';





const VerticalMenu = () => {
    // const userRole = useSelector((state: any) => state.profile.role ?? "");
    const userRole = localStorage.getItem("role");

    useEffect(() => { }, [])
    return (
        <>
            <div id="vertical_menu" className="verticle-menu px-2">
                <div className="menu-list">
                    {
                        userRole !== "ADMIN_COMPLIANCE_USER" &&
                        <>
                            <Link id="t-1" to={'/dashboard'} className="menu-item"> <img src={iconDashboard} alt="icon" className="menu-icon" /> <span className='nav-text'>Dashboard</span></Link>
                            <Link id="t-1" to={'/manage-clients'} className="menu-item"> <img src={icon4} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Clients</span></Link>
                            <Link id="t-1" to={'/integration'} className="menu-item"> <img src={icon3} alt="icon" className="menu-icon" /> <span className='nav-text'>Connectors</span></Link>
                            <Link id="t-1" to={'/compliance-user'} className="menu-item"> <img src={iconComplienceUser} alt="icon" className="menu-icon" /> <span className='nav-text'>Compliance Officer</span></Link>
                            <Link id="t-1" to={'/manage-admin'} className="menu-item"> <img src={iconManageAdmin} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Admin</span></Link>
                            <Link id="t-1" to={'/subscription'} className="menu-item"> <img src={iconSubscription} alt="icon" className="menu-icon" /> <span className='nav-text'>Subscription</span></Link>
                            <Link id="t-1" to={'/imessage'} className="menu-item"> <img src={iconImessage} alt="icon" className="menu-icon" /> <span className='nav-text'>iMessage</span></Link>
                            <Link id="t-1" to={'/whatsapp'} className="menu-item"> <img src={iconWhatsapp} alt="icon" className="menu-icon" /> <span className='nav-text'>WhatsApp</span></Link>
                            <Link id="t-1" to={'/connectors-report'} className="menu-item"> <img src={iconReport} alt="icon" className="menu-icon" /> <span className='nav-text'>Connectors Report</span></Link>
                            <Link id="t-1" to={'/keywords'} className="menu-item"> <img src={iconkeyword} alt="icon" className="menu-icon" /> <span className='nav-text'>AI Feedback</span></Link>
                        </>
                    }
                    {
                        userRole === "ADMIN_COMPLIANCE_USER" &&
                        <Link id="t-1" to={'/keywords'} className="menu-item"> <img src={iconkeyword} alt="icon" className="menu-icon" /> <span className='nav-text'>AI Feedback</span></Link>
                    }
                </div>
            </div>


        </>
    )
}
export default VerticalMenu;