export const updateProfileImage = (imageUrl) => ({
    type: 'UPDATE_PROFILE_IMAGE',
    payload: imageUrl,
  });

  export const updateUserRole = (role) => ({
    type: 'USER_ROLE',
    payload: role,
  });

  export const updateUserId = (id) => ({
    type: 'USER_ID',
    payload: id,
  });


export const otherService = (email) => ({
    type: 'OTHER_SERVICE',
    payload: email,
});

export const setOtherServiceOn = (bool) => ({
  type: 'SET_OTHER_SERVICE_ON',
  payload: bool,
});
  

 